<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  width: 13em;
  height: 2.5em;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
</style>
<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleAddTaskModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form lazy-validation ref="teamForm" class="pt-10">
            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-2">
                <v-text-field
                  label="Task Title"
                  outlined
                  dense
                  class="formFields"
                  color="#7253CF"
                  v-model="task_title"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedStartDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Start Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-2">
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="start_time"
                  placeholder="Start Time"
                  input-width="100%"
                  auto-scroll
                  :manual-input="true"
                  :minute-interval="5"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="6" class="pl-2">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedEndDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="End Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-2">
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  v-model="end_time"
                  placeholder="End Time"
                  input-width="100%"
                  auto-scroll
                  :manual-input="true"
                  :minute-interval="5"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="note"
                  label="Note"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 px-10">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-4 justify-content-center"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Add New Task</span>
          </v-btn>
          <v-btn
            :loading="formLoading"
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import { API_CALENDAR_POST_TASK_DATA } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import Helper from "@/helper";
import moment from "moment";
export default {
  name: "AddTaskModal",
  components: { VueTimepicker },
  data() {
    return {
      rules: RULES,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      loading: false,
      formLoading: false,
      start_date: "",
      end_date: "",
      start_time: "08:00 AM",
      end_time: "08:00 AM",
      task_title: "",
      note: "",
      user_id: localStorage.getItem("user_id"),
      ptoDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowTask",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddTaskModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.addTaskModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
    formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedStartTime: {
      get() {
        if (this.start_time) {
          return this.timeFormatter(this.start_time);
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEndTime: {
      get() {
        if (this.end_time) {
          return this.timeFormatter(this.end_time);
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleAddTaskModal: "calendarManagement/toggleTaskModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      if (this.type == "edit") {
        this.getDetail();
      }
    },
    getDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.ptoDetail = res.data.calendar_detail;
        this.task_title = this.ptoDetail.task_title;
        this.note = this.ptoDetail.note;
        this.start_date = this.ptoDetail.start_date;
        this.end_date = this.ptoDetail.end_date;
        this.start_time = Helper.convertFrom24To12Format(
          this.ptoDetail.start_time_str
        );
        this.end_time = Helper.convertFrom24To12Format(
          this.ptoDetail.end_time_str
        );
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        "/calendar/" +
          this.$store.state.calendarManagement.addTaskModal.taskID +
          "/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    closeCallBackModal() {
      this.loading = false;
      this.formLoading = false;
      this.name = "";
      this.email = "";
      this.role = null;
      this.roleList = [];
      this.$refs.teamForm.reset();
    },
    timeFormatter(time) {
      let timeInt = parseInt(time);
      let minutes = time.substring(3, 5);
      if (time.split(":")[0] == "00") return `12:${time.split(":")[1]} AM`;
      if (time >= "13:00") return `${timeInt - 12}:${minutes} PM`;
      else if (time >= "12:00") return `${timeInt}:${minutes} PM`;
      else return `${timeInt}:${minutes} AM`;
    },
    submitForm() {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;
        self.toggleAddTaskModal({ show: false });
        self.$emit("reload");
        location.reload();
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["event_type"] = "Task";
      formData["task_title"] = this.task_title;
      formData["start_date"] = this.formatedStartDate;
      if (this.formatedStartTime) {
        formData["start_time"] = this.formatedStartTime;
      }
      formData["end_date"] = this.formatedEndDate;
      if (this.formatedEndTime) {
        formData["end_time"] = this.formatedEndTime;
      }
      formData["owner"] = this.user_id;
      formData["note"] = this.note;
      formData["event_category"] = 5;

      console.log(formData);
      if (this.type === "add") {
        Axios.request_POST(
          API_CALENDAR_POST_TASK_DATA,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        Axios.request_PATCH(
          "/calendar/" +
            this.$store.state.calendarManagement.addTaskModal.taskID +
            "/",
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
